<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Tworzenie nowego wydarzenia</div>
      </div>
      <div class="card-content">
        <div class="block">
          <h1 class="title is-5">Tytuł</h1>
          <div>
            <b-field grouped>
              <b-input v-model="form.title" type="text" expanded></b-input>
            </b-field>
          </div>
        </div>
        <hr />
        <div class="block">
          <h1 class="title is-5">Opis</h1>
          <div>
            <b-field grouped>
              <b-input
                v-model="form.description"
                type="textarea"
                expanded
              ></b-input>
            </b-field>
          </div>
        </div>
        <hr />
        <div class="block">
          <h1 class="title is-5">Data wydarzenia</h1>
          <div class="block">
            <b-field grouped>
              <b-datepicker
                v-model="form.date"
                position="is-top-right"
              ></b-datepicker>
              <b-input v-model="form.hour" type="time"></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <a href="#" class="card-footer-item has-text-dark" @click="cancel"
          >Anuluj</a
        >
        <a
          href="#"
          class="card-footer-item has-text-primary"
          @click="createEvent"
          >Dodaj</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      isLoading: false,

      form: {
        title: "",
        date: new Date(),
        hour: "",
        description: "",
      },
    };
  },

  methods: {
    cancel() {
      for (const key in this.form) {
        this.form[key] = "";
      }

      this.$emit("cancelEventCreate");
    },

    createEvent() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$buefy.notification.open({
          message: "Nie wypełniono wszystkich pól",
          type: "is-danger",
          position: "is-top",
          duration: 10000,
        });
        return;
      }

      const day = this.form.date.getDate();
      const month = this.form.date.getMonth() + 1;
      const year = this.form.date.getFullYear();

      const dateToSet = `${year}-${month}-${day} ${this.form.hour}`;
      const uid = this.$store.getters["companyProfile/uid"];

      const eventData = {
        title: this.form.title,
        description: this.form.description,
        date: dateToSet,
        company_id: uid,
      };

      this.isLoading = true;
      this.$store
        .dispatch("companyOrders/createEvent", eventData)
        .then((response) => {
          //console.log(response);
          if (response.status !== 201) {
            this.$buefy.notification.open({
              message:
                "Nie udało się utworzyć wydarzenia. Proszę spróbować później lub skontaktować się z administratorem",
              type: "is-danger",
              position: "is-top",
              duration: 10000,
            });
          } else {
            this.$buefy.notification.open({
              message: "Wydarzenie zostało utworzone",
              type: "is-primary",
              position: "is-top",
              duration: 10000,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$buefy.notification.open({
            message: "Błąd serwera. Proszę spróbować później.",
            type: "is-danger",
            position: "is-top",
            duration: 10000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  validations: {
    form: {
      title: {
        required,
      },
      date: {
        required,
      },
      hour: {
        required,
      },
      description: {
        required,
      },
    },
  },
};
</script>