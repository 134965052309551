<template>
  <div>
    <company-calendar></company-calendar>
  </div>
</template>
<script>
import CompanyCalendar from "@/components/company/CompanyCalendar";

export default {
  components: {
    "company-calendar": CompanyCalendar,
  },

  created() {},
};
</script>