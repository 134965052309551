<template>
  <div>
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <b-button
            type="is-primary"
            icon-left="plus"
            @click="showCreateOrderModal"
            rounded
            >Nowe wydarzenie</b-button
          >
        </div>
      </div>
    </div>
    <vue-cal
      class="vuecal-root"
      active-view="week"
      :disable-views="['years']"
      locale="pl"
      :time-from="0 * 60"
      :time-to="24 * 60"
      :time-step="60"
      :events="companyEvents"
      :on-event-click="onEventClick"
    ></vue-cal>

    <b-modal v-model="createOrderModalShow">
      <div>
        <custom-event-creator
          @cancelEventCreate="closeCreateEventModal"
        ></custom-event-creator>
      </div>
    </b-modal>
    <!--MODAL GO TO OR EDIT-->
    <b-modal
      v-model="manageEvent"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Event Manage"
      aria-modal
      class="tile is-12 event"
    >
      <div class="columns ">
        <div class="column is-12 is-offset-6">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p>
                <b>{{ event.title }}</b>
                <b-button
                  class="order-button is-primary"
                  v-if="event && event.order_id"
                  @click="
                    $router.push({
                      name: 'OrderDetails',
                      params: { id: event.order_id },
                    })
                  "
                  >Przejdź do zamówienia {{ event.order_id }}</b-button
                >
              </p>
            </header>
            <section class="modal-card-body">
              <b-field label="Tytuł">
                <b-input
                  type="text"
                  v-model="event.title"
                  :placeholder="event.title"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="Opis">
                <b-input
                  type="textarea"
                  v-model="event.content"
                  :placeholder="event.content"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="Wybierz date">
                <b-datetimepicker
                  placeholder="Type or select a date..."
                  icon="calendar-today"
                  v-model="event.start"
                  locale="pl"
                  editable
                >
                </b-datetimepicker>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <b-button label="Anuluj" @click="manageEvent = false" />
              <b-button
                label="Edytuj zdarzenie"
                type="is-primary"
                @click="editEvent"
              />
            </footer>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CustomEventCreator from "@/components/company/CustomEventCreator";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/pl";

export default {
  components: {
    "custom-event-creator": CustomEventCreator,
    "vue-cal": VueCal,
  },

  data() {
    return {
      createOrderModalShow: false,
      manageEvent: false,
      event: {},
    };
  },

  methods: {
    showCreateOrderModal() {
      this.createOrderModalShow = true;
    },

    closeCreateEventModal() {
      this.createOrderModalShow = false;
    },

    onEventClick(event) {
      this.event = event;
      this.manageEvent = true;
    },

    editEvent() {
      let data = {
        order_id: this.event.order_id,
        title: this.event.title,
        date: this.event.start,
        description: this.event.description,
      };
      let companyId = this.event.company_id;
      let eventId = this.event.id;

      this.$store
        .dispatch("companyOrders/editEvent", {
          data: data,
          company: companyId,
          event: eventId,
        })
        .then((res) => {
          res
          this.manageEvent = false
          //console.log(res);
        });
    },
  },

  computed: {
    companyEvents() {
      return this.$store.getters["companyOrders/getCompanyEvents"];
    },
  },
};
</script>

<style scoped>
.vuecal-root::v-deep .vuecal__cell {
  min-width: fit-content;
}
.vuecal-root::v-deep .vuecal__event {
  cursor: pointer;
  color: white;
  background-color: #ec7931;
  border: 1px solid rgb(255, 255, 255);
}

.vuecal-root::v-deep .vuecal__event:hover {
  box-shadow: 0 0 1em #e66413;
  width: 200% !important;
  height: 100px !important;
  border: 2px solid black;
  transition: 0.3s;
}

.dropdown-menu {
  z-index: 100;
}

.order-button {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.event{
  height: 100%;
}
</style>